import React from 'react';
import { getCalendar } from './Interface.js'
import DateRangePicker from './date-range-picker/DateRangePicker.jsx' //http://onefinestay.github.io/react-daterange-picker/ https://github.com/onefinestay/react-daterange-picker
import './date-range-picker/css/react-calendar.scss' // For some basic styling. (OPTIONAL)
import FilterButton from './FilterButton'
import { st, pt } from './texte'
import { ReactComponent as MaleIcon } from './img/male.svg'
import { ReactComponent as FemaleIcon } from './img/female.svg'
import { ReactComponent as CalIcon } from './img/cal.svg'
import { ReactComponent as GutzeichenIcon } from './img/gutzeichen.svg'
import { ReactComponent as KreuzIcon } from './img/kreuz-01.svg'

const SuchFilter = (props) => {  
  const showCalendar = event => {
    props.setAppState({pop_calendar: true});
  }
  const hideCalendar = event => {
    props.setAppState({pop_calendar: false});
  }
  const dateChange = (range, states) => {  
    console.log(['newrange', range.start.format(), range.end.format()])
    var naechte = range.end.diff(range.start, 'days');
    if (naechte > props.appState.konfig.maxbookingdays) {
      naechte = props.appState.konfig.maxbookingdays;
    }
    props.setAppState({filterAnkunft: range.start, filterNaechte:naechte, pop_calendar: false}, props.getAngebote);
  }
  const selectNaechte = event => {
    props.setAppState({filterNaechte:parseInt(event.target.value)}, props.getAngebote);
  }
  const changeErwachsene = event => {
    var v = parseInt(event.target.value);
    props.setAppState((state)=>{ 
      return { filterErwachsene: Math.min(v, state.konfig.maxbookingpersons - state.filterKind + state.filterKind2) }
    }, props.getAngebote);
  };
  const updateGeschlechtFilter = (v, mod, state) => {
    var nofilter = !state.konfig.childindorm && v>0;
    mod.nofilter = nofilter;
    if (nofilter) {
      if (state.konfig.childinprivat) {
        mod.filterGeschlecht = [];    
        mod.filterMultiroomfilter = {"easypriv":true,"multiroom":true};
      }
      else {
        mod.filterGeschlecht = [0,1,2];    
        mod.filterMultiroomfilter = {"easy":true};
      }
    }
    else {
        mod.filterGeschlecht = [];    
        mod.filterMultiroomfilter = {};
        mod.filterTicked = {};
    }
  }
  const changeKinder = event => {
    var v = parseInt(event.target.value);
    props.setAppState((state)=>{ 
      var mod = { filterKind: Math.min(v, state.konfig.maxbookingpersons - state.filterErwachsene - state.filterKind2)};
      updateGeschlechtFilter(v+state.filterKind2, mod, state);
      return mod;
    }, props.getAngebote);
  };
  const changeKinder2 = event => {
    var v = parseInt(event.target.value);
    props.setAppState((state)=>{ 
      var mod = {filterKind2: Math.min(v, state.konfig.maxbookingpersons - state.filterErwachsene - state.filterKind)};
      updateGeschlechtFilter(v+state.filterKind, mod, state);
      return mod;
    }, props.getAngebote);
  };
  
  const handleRoomFilter = (t) => event => {
    props.setAppState((state) => {
      var mf = state.filterMultiroomfilter;
      var gf = state.filterGeschlecht;
      var ft = state.filterTicked;
      var ticked = !ft[t];
      ft[t] = ticked;

      mf[t] = ticked;
      if (!mf.easy && mf.easypriv) {
        gf = [];
        ft[0] = false;
        ft[1] = false;
        ft[2] = false;
      }
      return { filterMultiroomfilter: mf, filterGeschlecht: gf, filterTicked: ft}
    }, props.getAngebote);
  };
  const handleGeschlechtFilter = (t) => event => {
    props.setAppState((state) => {
      var mf = state.filterMultiroomfilter;
      var gf = state.filterGeschlecht;
      var ft = state.filterTicked;
      var ticked = !ft[t];
      ft[t] = ticked;
      
      var p = gf.indexOf(t);
      if (ticked && p < 0) {
        gf.push(t);
      }
      if (!ticked && p > -1) {
        gf = gf.filter(item => item !== t);
      }
      if (gf.length>0) {
        mf.easy = true;
        ft.easy = true;
      }
      return { filterMultiroomfilter: mf, filterGeschlecht: gf, filterTicked: ft}
    }, props.getAngebote);
  };
  
  const { appState, teststyle, dateFormat, moment, changeCode, specialdays } = props;
  const { konfig, status, paymentinfo, sprache, filterTicked } = appState;

  if (status!=='angebot')
    return null;

  const naechteListe = [];
  for(var i=1; i<=konfig.maxbookingdays; i++) {
    naechteListe.push(<option value={i} key={i}>{i + ' ' + st['bwh.buchungsdaten.naechteselect.'+(i===1?'singular':'plural')]}</option>);
  }
  const personenListe = [];
  const erwachseneListe = [];
  const kinderListe = [];
  const kinder2Liste = [];
  var kinderKats = [];
  if (konfig.childalterskat) kinderKats.push(konfig.childalterskat);
  if (konfig.childalterskat2) kinderKats.push(konfig.childalterskat2);
  var kindTexte = [];
  kinderKats.forEach((kat, i) => {
    if (pt.agecategory_description && pt.agecategory_description[parseInt(kat)]) {
      const c = pt.agecategory_description[kat].split('/');
      if (c[1]) {
        kindTexte.push({s:c[0], p:c[1]});
      }
      else {
        kindTexte.push({s:c[0], p:c[0]});
      }
    }
    else {
      kindTexte.push({s:st['bwh.buchungsdaten.kinderselect.singular'], p:st['bwh.buchungsdaten.kinderselect.plural']});
    }
  });
  for(i=0; i<=konfig.maxbookingpersons; i++) {
    if (i>0) {
      personenListe.push(<option value={i} key={i}>{i + ' ' + st['bwh.buchungsdaten.personenselect.'+(i===1?'singular':'plural')]}</option>);
      erwachseneListe.push(<option value={i} key={i}>{i + ' ' + st['bwh.buchungsdaten.erwachseneselect.'+(i===1?'singular':'plural')]}</option>);
    }
    var anz = (i===0?st['bwh.buchungsdaten.keine']:i);
    if (kinderKats.length>0) {
      kinderListe.push(<option value={i} key={i}>{anz + ' ' + kindTexte[0][i===1?'s':'p']}</option>);
      if (kindTexte[1]) {
        kinder2Liste.push(<option value={i} key={i}>{anz + ' ' + kindTexte[1][i===1?'s':'p']}</option>);
      }
    }
  }
  
  const childaltersspan = konfig.childaltersspan?konfig.childaltersspan.split(','):['5','12'];
  const childaltersspan2 = konfig.childaltersspan2?konfig.childaltersspan2.split(','):['5','12'];

  const gch = parseInt(konfig.genderchoice);

  var ankunft = moment(appState.filterAnkunft);
  ankunft.locale(sprache)
  
  var avrows = [];  
  var p = appState.avpreview;
  const personen = appState.filterErwachsene + appState.filterKind + appState.filterKind2;
  if (p) {
    var avhead = [(<td id="vorschaubereichlabel" width="20%" key={0}>{st['bwh.unterkunftsdaten.avpreviewlabel']}</td>)];
    var rowcnt = 0;
    var colwidth = 20;
    var an = parseInt(appState.filterAnkunft.format('YYYYMMDD'));
    var ab = parseInt(moment(appState.filterAnkunft).add(appState.filterNaechte, 'day').format('YYYYMMDD'));
    for (var typ in p) {
      for (var date in p.dorm) rowcnt++;
      colwidth = 80/rowcnt;
      var rowtitel = {dorm:'shared', privat: 'private', multi:'multiroom'};
      var avrow = [(<td id="sharedtext" key={0}>{st["bwh.unterkunftsdaten.input."+rowtitel[typ]]}</td>)];
      for (date in p[typ]) {
        if (avrows.length===0) {
          var d = moment(date);
          avhead.push(<td align="center" key={date} width={colwidth+"%"} className={date>=an && date<ab?"selected":null}>{d.format('ddd')}<br />{d.format(sprache!=='de'?'MMM Do':'Do MMM')}</td>);
        }
        var cell = p[typ][date];
        
        var statclass = 'status'+((cell.cnt > 0) + (cell.cnt === personen));
        var preistext = '';
        if (cell.cnt > 0) {
          var preis = cell.preis.total;
          if (konfig.pricenocomm > 0) {
            preis = cell.preis.room + cell.preis.rabatt;
            if (konfig.pricenocomm === 2) { // NetPrice+Fee
              preis += cell.preis.commission;
            }
            if (konfig.pricenocomm === 3) { // NetPrice+Service
              preis += cell.preis.service;
            }
          }
          preis = preis / cell.cnt * personen
          if (konfig.showaverageprice) {
            preis = preis / personen;
          } 
          preistext = st['bwh.preis.ab']+' '+preis.toFixed(paymentinfo.decimal);                                                                                                
        }
        
        avrow.push(<td key={date} className={statclass} align="center">{preistext}</td>);
        
      }
      if (avrows.length===0) {
        avrows.push(avhead);
      }
      avrows.push(avrow);
    }
  }
  
  const arrivalday = moment(appState.filterAnkunft).set({hour:0,minute:0,second:0,millisecond:0})
  const departureday = moment(arrivalday).add(appState.filterNaechte, 'days')
  const selecteddates=moment.range(arrivalday,departureday)

  const stateDefinitions = {
    available: {
      color: '#92D154',
      label: st['bwh.buchungsdaten.kalender.avail'],
    },
    enquire: {
      color: '#ffd200',
      label: st['bwh.buchungsdaten.kalender.limited'],
    },
    unavailable: {
      //selectable: false, // feedback whistler-lodge: offenbar merken einige Gäste nicht, dass die Daten nicht gewählt werden können. 
      color: '#ed5434',
      label: st['bwh.buchungsdaten.kalender.noavail'],
    },
    past: {
      selectable: false,
      color: '#dddddd',
    },
  };
  const viewChange = (md) => {  
    var day = new Date(md.year, md.month, 1);
    const m = moment(day);
    getCalendar(appState, props.setAppState, m.format('YYYYMMDD'));
    getCalendar(appState, props.setAppState, m.add(1, 'month').format('YYYYMMDD'));
    getCalendar(appState, props.setAppState, m.add(1, 'month').format('YYYYMMDD'));
  }

  viewChange({year:moment().format('YYYY'), month:moment().format('MM')-1});
  viewChange({year:arrivalday.format('YYYY'), month:arrivalday.format('MM')-1});
  
  return (
      <div id="suchFilter_wrap" className="clearfix">
        <div id="termin_wrap" className="filterbereich-wrap clearfix" style={teststyle}>
          <div className="filterbereich clearfix">
            <div className="filterbereich-titel">
              <p id="terminbereichlabel" className="filterbereich-titeltext">{st['bwh.buchungsdaten.bereichlabel']}</p>
            </div>
            <div className="bereichinhalt-wrap no-left-padding clearfix">
              <div className="filterbereich-felder-zeile clearfix">
                <div className="clearfix filterbereich-felder-3" style={{display:appState.pop_calendar?'none':'block'}}>
                  <input type="text" name="ankunft" id="terminAnkunft" className="filterbereich-feld" value={ankunft.format(dateFormat) + ' - ' + ankunft.add(appState.filterNaechte, 'days').format(dateFormat)} readOnly onClick={showCalendar} />
                  <CalIcon className="image filterbereich-calendar-icon" onClick={showCalendar} />
                </div>
                <div className="clearfix filterbereich-felder-3" style={{display:appState.pop_calendar?'none':'block'}}>
                  <select name="naechte" value={appState.filterNaechte} id="terminNaechte" className="filterbereich-feld" onChange={selectNaechte}>
                      {naechteListe}                       
                  </select>
                </div>
  {appState.pop_calendar &&
                <div style={{position:'relative', display:'inline-block', marginLeft:'15px', backgroundColor:'#FFF', zIndex:10}}>
                  <DateRangePicker
                    locale={sprache}
                    firstOfWeek={1}
                    minimumDate={appState.minDate}
                    numberOfCalendars={2}
                    selectionType="range"
                    onSelect={dateChange}
                    dateStates={appState.calendarDateRanges}
                    stateDefinitions={stateDefinitions}
                    selectedLabel={st['bwh.buchungsdaten.kalender.selected']}
                    showLegend={appState.calendarDateRanges.length > 0}
                    defaultState="available"
                    value={selecteddates}
                    onViewChange={viewChange}
                  />
                  <div style={{position:'absolute', top:'0.6em', right:'0.5em', cursor:'pointer'}} onClick={hideCalendar}>X</div>
                </div>
  }
              </div>
  {specialdays!==false &&
              <table style={{margin: "0 0 10px 45px"}}><tbody>
                {specialdays}    
              </tbody></table>
  }
              <div className="filterbereich-felder-zeile clearfix">
  {!konfig.bookwithchild &&
                <div className="clearfix filterbereich-felder-3">
                  <select name="personen" value={appState.filterErwachsene} id="terminPersonen" className="filterbereich-feld" onChange={changeErwachsene}>
                    {personenListe}
                  </select>
                </div>
  }
  {!!konfig.bookwithchild && !konfig.childalterskat2 &&
                <React.Fragment>                 
                <div className="clearfix filterbereich-felder-6-l">
                  <select name="erwachsene" value={appState.filterErwachsene} id="terminErwachsene" className="filterbereich-feld" onChange={changeErwachsene}>
                    {erwachseneListe.slice(0,konfig.maxbookingpersons-appState.filterKind)}
                  </select>
                </div>
                <div className="clearfix filterbereich-felder-6-r">
                  <select name="kinder" value={appState.filterKind} id="terminKinder" className="filterbereich-feld" onChange={changeKinder}>
                    {kinderListe.slice(0,konfig.maxbookingpersons-appState.filterErwachsene+1)}
                  </select>
                </div>
                <div id="childaltersspantext_wrap" className="clearfix filterbereich-felder-text-wrap">
                  <p id="childaltersspantext" className="filterbereich-felder-text">
                    {st['bwh.buchungsdaten.kinderselect.plural']+ ': ' + childaltersspan[0] + ' - ' + childaltersspan[1] + ' ' + st['bwh.buchungsdaten.jahre']}
                  </p>
                </div>
                </React.Fragment>
  }
  {!!konfig.bookwithchild && !!konfig.childalterskat2 &&
                <React.Fragment>                 
                <div className="clearfix filterbereich-felder-6-l">
                  <select name="erwachsene" value={appState.filterErwachsene} id="terminErwachsene" className="filterbereich-feld" onChange={changeErwachsene}>
                    {erwachseneListe.slice(0,konfig.maxbookingpersons-appState.filterKind-appState.filterKind2)}
                  </select>
                </div>
                <div className="clearfix filterbereich-felder-6-r">
                  <select name="kinder" value={appState.filterKind} id="terminKinder" className="filterbereich-feld" onChange={changeKinder} style={{float:'none'}}>
                    {kinderListe.slice(0,konfig.maxbookingpersons-appState.filterErwachsene-appState.filterKind2+1)}
                  </select>
                  <div style={{padding:'4px 0 0 4px'}}>{childaltersspan[0] + ' - ' + childaltersspan[1] + ' ' + st['bwh.buchungsdaten.jahre']}</div>
                </div>
                <div className="clearfix filterbereich-felder-6-r">
                  <select name="kinder2" value={appState.filterKind2} id="terminKinder2" className="filterbereich-feld" onChange={changeKinder2} style={{float:'none'}}>
                    {kinder2Liste.slice(0,konfig.maxbookingpersons-appState.filterErwachsene-appState.filterKind+1)}
                  </select>
                  <div style={{padding:'4px 0 0 4px'}}>{childaltersspan2[0] + ' - ' + childaltersspan2[1] + ' ' + st['bwh.buchungsdaten.jahre']}</div>
                </div>
                </React.Fragment>
  }
  {false &&
                <div className="clearfix filterbereich-felder-3">
                  <button type="button"  id="terminErweitertButton" className="filterbereich-feld">{st['bwh.buchungsdaten.input.erweitert']}</button>
                </div>
  }                  
              </div>
            </div>
          </div>
        </div>
  {(!appState.nofilter && (gch!==6) && (gch!==5 || !!konfig.multiroomoption)) &&
        <div id="unterkunft_wrap" className="filterbereich-wrap clearfix" style={teststyle}>
          <div className="filterbereich clearfix">
            <div className="filterbereich-titel">
              <p id="unterkunftbereichlabel" className="filterbereich-titeltext">{st['bwh.unterkunftsdaten.bereichlabel']}</p>
            </div>
            <div className="bereichinhalt-wrap clearfix">
              <div className="toggle-btn-grp filterbereich-felder-zeile clearfix" id="unterkunftPrivat">
    {gch!==5 &&
                <FilterButton text={st['bwh.unterkunftsdaten.input.shared']} ticked={filterTicked.easy} update={handleRoomFilter('easy')} />
    }
                <FilterButton text={st['bwh.unterkunftsdaten.input.private']} ticked={filterTicked.easypriv} update={handleRoomFilter('easypriv')} />
    {konfig.multiroomoption ?              
                <FilterButton text={st['bwh.unterkunftsdaten.input.multiroom']} ticked={filterTicked.multiroom} update={handleRoomFilter('multiroom')} />
    :null}
              </div>
  {(gch!==2 && gch!==5) &&
              <div className="toggle-btn-grp filterbereich-felder-zeile clearfix" id="unterkunftGeschlecht">
    {gch!==4 &&
                <FilterButton text={st['bwh.unterkunftsdaten.input.mixed']} icons={[<FemaleIcon key="F" />, <MaleIcon key="M" />]} ticked={filterTicked[2]} update={handleGeschlechtFilter(2)} />
    }                              
    {gch!==0 &&
                <FilterButton text={st['bwh.unterkunftsdaten.input.female']} icons={[<FemaleIcon key="F" />]} ticked={filterTicked[0]} update={handleGeschlechtFilter(0)} />
    }                              
    {gch===1 &&
                <FilterButton text={st['bwh.unterkunftsdaten.input.male']} icons={[<MaleIcon key="M" />]} ticked={filterTicked[1]} update={handleGeschlechtFilter(1)} />
    }                              
              </div>
  }                              
            </div>
          </div>
        </div>
  }
  {konfig.hasrabatt?
        <div id="coupon_wrap" className="filterbereich-wrap clearfix" style={teststyle}>
          <div className="filterbereich clearfix">
            <div className="filterbereich-titel">
              <p id="couponbereichlabel" className="filterbereich-titeltext">{st['bwh.coupondaten.bereichlabel']}</p>
            </div>
            <div className="bereichinhalt-wrap">
              <div className="filterbereich-felder-zeile clearfix">
                <div className="clearfix filterbereich-felder-3">
                  <input type="text" name="coupon" id="couponCode" value={appState.filterCoupon} className="filterbereich-feld" onChange={changeCode} />
        {appState.validCode &&
                  <GutzeichenIcon className="toggle-btn-gutzeichen image" alt="checked" />
        }
        {appState.filterCoupon!=='' && !appState.validCode &&
                  <KreuzIcon className="toggle-btn-gutzeichen invalid image" alt="invalid" />
        }
                </div>
              </div>
            </div>
          </div>
        </div>
  :null}          
        <div id="avpreview_wrap" className="filterbereich-wrap clearfix" style={teststyle}>
          <div id="avpreview" className="filterbereich">
            <table width="100%"><tbody>
    {avrows.map((el, k)=> (        
              <tr key={k}>{el}</tr>
    ))}
            </tbody></table>
          </div>
        </div>
      </div>
  );
}
export default SuchFilter;
