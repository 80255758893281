import React from 'react';
import { st, pt, replaceRateText } from './texte'
import { findRate } from './common'
import PaymentTable from './PaymentTable';

export class Rates extends React.Component {

  render (){
    const {appState, paymentinfo, selectRate, moment, dateFormat} = this.props;

    var ag = appState.bookingData.angebot.list[0];
    var pp = ag.paymentPoints;
    if (!pp) return null;

    var rateList2 = [];
    for (var i=0; i<ag.rates.length; i++) {
      var rateid = ag.rates[i].rate_id;
      var points = pp[rateid];
      var rate = findRate(ag.rates, rateid);
      var graph = [];
      var oldh = 150;
      const pos = [['start', -30],['middle',0],['end',50]];
      for (var j=0;j<points.length;j++) {
        var point = points[j];
        var x = j * 150 + 30;
        if (point.date) {
          var h = 150 - (point.preis / rate.total * 125);
          graph.push(<text fill="#FFF" textAnchor="middle" x={x} y="180" key={j*10+1}>{st[point.dateText]}</text>);
          graph.push(<line x1={x} y1={oldh} x2={x} y2={h} strokeWidth="3" stroke="#7FB14D" key={j*10+2}/>);
          graph.push(<line x1={x} y1={h} x2={x+150} y2={h} strokeWidth="3" stroke="#7FB14D" key={j*10+3}/>);
          if (h !== oldh) {
            var t = (Math.round(point.preis*10)/10).toFixed(paymentinfo.decimal);
            graph.push(<text fill="#FFF" textAnchor={pos[j][0]} x={x+pos[j][1]} y={h-10} key={j*10+4}>{t}</text>);
          }
          oldh = h;
        }
        else {
          graph.push(<line x1={x} y1={oldh} x2={x+150} y2={oldh} strokeWidth="3" stroke="#7FB14D" key={j*10+5}/>);
        }
      }
      var ratehtml = false;
      if (rateid > 0) {
        ratehtml = rate.details;
        ratehtml = replaceRateText(ratehtml, moment, dateFormat);
      }
      
        
      rateList2.push (
        <div className="clearfix angebot_wrap rates" key={rateid}>
          <div className="clearfix angebot">
            <div className="room vis" onClick={selectRate(rateid)}>
              <div className={"clearfix choice"+((rateid===appState.selectedRate)?" choice_selected":"")}>
                <div className="image gutzeichen"></div>
                <p className="my_choice">{st['bwh.zusatz.ausgewaehlt']}</p>
              </div>
              <div className="clearfix roomverlauf">
                <svg version="1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 382 181" width="100%" height="80%">
                  {graph}
                </svg>
              </div>
              <div className="clearfix roompreis_wrap">
                <p className="roompreis">{rate.total.toFixed(paymentinfo.decimal)}</p>
                <p className="roomwaehrung">{paymentinfo.waehrung}</p>
              </div>
            </div>
            <div className="clearfix angebotbeschreibung">
              <div className="angebotbeschreibung-zeile1">
                <p className="angebotbeschreibung-zeile1-text">{pt.rate_title[rateid]}</p>
              </div>
      {rateid > 0 &&
              <PaymentTable appState={appState} ratedetails={rate.allpoints} moment={moment} dateFormat={dateFormat} total_noservice={55} paynow={44} />
      }
            </div>
          </div>
        </div>
      
      );
    }
    if (rateList2.length) {
      return rateList2;
    }
    else {
      return null;
    }
  }
}
export default Rates;
