import React from 'react';
import { st } from './texte'
import { ReactComponent as GutzeichenIcon } from './img/gutzeichen.svg'
import { ReactComponent as KreuzIcon } from './img/kreuz-01.svg'

class InputFeld extends React.Component {
  render() {
    const { name, titel, value, change, required, valid } = this.props;
    var val = value?value:'';

    return (
      <div className="formularfeld clearfix">
        <p className="formularfeld-label">{titel}{required?" *":""}</p>
        <input type="text" name={"feld_"+name} value={val} className={"formularfeld-feld"+(required?" required":"")} onChange={change(name)} />
      {valid===1 &&
        <GutzeichenIcon className="formularfeld-gutzeichen image" />
      }
      {valid===-1 &&
        <KreuzIcon className="formularfeld-kreuzzeichen image" />
      }
      </div>
    );
  }
}

class SelectFeld extends React.Component {
  render() {
    const { name, titel, value, change, required, valid, list} = this.props;
    var val = value?value:'0';

    var has0 = false;
    var options = [];
    for (var i=0; i<list.length;i++) {
      var v = list[i].value;
      if (v==='0' || v===0) {
        has0 = true;
      }
      options.push(<option value={v} key={i}>{list[i].text}</option>);
    }
    if (required && !has0) {
      options.unshift(<option value="0" key="empty0">{st['bwh.bittewaehlen']}</option>)
    }
    
    return (
      <div className="formularfeld clearfix">
        <p className="formularfeld-label">{titel}{required?" *":""}</p>
        <select type="text" name={"feld_"+name} value={val} className={"formularfeld-feld"+(required?" required":"")} onChange={change(name)} >
          {options}
        </select>
      {valid===1 &&
        <GutzeichenIcon className="formularfeld-gutzeichen image" />
      }
      {valid===-1 &&
        <KreuzIcon className="formularfeld-kreuzzeichen image" />
      }
      </div>
    );
  }
}

class DatumFeld extends React.Component {
  render() {
    const { name, titel, value, change, required, valid} = this.props;
    var val = value?value:'19900101';

    var years = [];
    var thisyear = new Date().getFullYear();
    for (var i=1900; i<=thisyear;i++) {
      years.push(<option value={i} key={i}>{i}</option>);
    }
    var months = [];
    for (i=1; i<=12;i++) {
      months.push(<option value={i} key={i}>{('0' + i).slice(-2)}</option>);
    }
    var days = [];
    for (i=1; i<=31;i++) {
      days.push(<option value={i} key={i}>{('0' + i).slice(-2)}</option>);
    }
    
    return (
      <div className="formularfeld clearfix">
        <p className="formularfeld-label">{titel}{required?" *":""}</p>
        <select type="text" name={"feld_"+name+'|Y'} value={Math.floor(val/10000)} className={"formularfeld-feld"+(required?" required":"")} onChange={change(name+'|Y')} style={{width:'auto',marginRight:'1em'}} >
          {years}
        </select>
        <select type="text" name={"feld_"+name+'|M'} value={Math.floor(val/100)%100} className={"formularfeld-feld"+(required?" required":"")} onChange={change(name+'|M')} style={{width:'auto',marginRight:'1em'}}>
          {months}
        </select>
        <select type="text" name={"feld_"+name+'|D'} value={val%100} className={"formularfeld-feld"+(required?" required":"")} onChange={change(name+'|D')} style={{width:'auto',marginRight:'1em'}}>
          {days}
        </select>
      {valid===1 &&
        <GutzeichenIcon className="formularfeld-gutzeichen image" />
      }
      {valid===-1 &&
        <KreuzIcon className="formularfeld-kreuzzeichen image" />
      }
      </div>
    );
  }
}

export { InputFeld, SelectFeld, DatumFeld };