import React from 'react';
import { getGenderText, st, pt, it } from './texte'
import { textileConvert } from './common'
import { ReactComponent as MaleIcon } from './img/male.svg'
import { ReactComponent as FemaleIcon } from './img/female.svg'
import { ReactComponent as PfeilUntenGrauIcon } from './img/pfeil_unten_grau.svg'
import { ReactComponent as PfeilObenGrauIcon } from './img/pfeil_oben_grau.svg'
import symbolicZimmerBild from './img/bed-symbolic.png'

export class Angebot extends React.Component {
 
  constructor(props) {
    super(props);
    this.state = {
      intervalId: -1,
      cnt : 0,
    };
  }  
  
  componentDidMount = () => {
    var intervalId = setInterval(this.timer, 5000);
    this.setState({intervalId: intervalId});
  }
  componentWillUnmount = () => {
    clearInterval(this.state.intervalId);
  }
  timer = () => {
    const len = this.props.data.rooms.length;
    if (len > 1) {
      this.setState((state) => ({ cnt: (state.cnt+1) % len }));    
    }
  }
  
  clickDetail = (i) => event => {
    this.setState((state) => ({ ["detail"+i]: !state["detail"+i] }));    
  }
  dummy = event => {
    
  }
  
  render () {
    const {data, hasRates, decimal, waehrung, selectedAngebotId, selectAngebot, gemeinsameIcons, konfig} = this.props;
    const geschlecht = data.rooms[0].geschlecht;

    var ptot = data.preis;
    var poc = data.preis - data.rabatt;
    if (konfig.showaverageprice) {
      poc = poc / data.nights / data.persons;
      ptot = ptot / data.nights / data.persons
    }
    
    var preisText = '';
    konfig.alterskats.forEach((ak, i)=>{
      if (data.preisAk[ak]) {
        const anz = data.aklist[ak];
        if (i===0) {
          preisText += anz + '\u00a0' + (anz>1?st['bwh.buchungsdaten.erwachseneselect.plural']:st['bwh.buchungsdaten.erwachseneselect.singular']) + '\u00a0x\u00a0' +(data.preisAk[ak] / anz).toFixed(decimal);
        }
        else if (data.preisAk[ak]) {
          if (pt.agecategory_description && pt.agecategory_description[parseInt(ak)]) {
            const c = pt.agecategory_description[ak].split('/');
            if (!c[1]) c[1] = c[0];
            preisText += ', ' + anz + '\u00a0' + (anz>1?c[1]:c[0]) + '\u00a0x\u00a0' +(data.preisAk[ak] / anz).toFixed(decimal);
          }
          else {
            preisText += ', ' + anz + '\u00a0' + (anz>1?st['bwh.buchungsdaten.kinderselect.plural']:st['bwh.buchungsdaten.kinderselect.singular']) + '\u00a0x\u00a0' +(data.preisAk[ak] / anz).toFixed(decimal);
          }
        }
      }
    });

    var iconsLinks = [];
    for (var k=0; k<data.rooms.length; k++) {
      var icons = konfig.icons[data.rooms[k].pkat];
      iconsLinks[k] = [];
      for (var ic=0; ic<icons.length; ic++) {
        var icon = icons[ic];
        if (gemeinsameIcons.indexOf(icon) < 0 && it.list[icon]) {
          iconsLinks[k].push(<i className={"flaticon-"+it.list[icon].name+" angeboticon"} title={it.list[icon].text} key={ic}></i>);
        }
      }
    }
    
    var iconsRechts = [];    
    if (gemeinsameIcons.length > 0) {
      iconsRechts.push(<div className="verticalLine" key="x"></div>);
      for (ic=0; ic<gemeinsameIcons.length; ic++) {
        icon = gemeinsameIcons[ic];
        if (it.list[icon]) {
          iconsRechts.push(<i className={"flaticon-"+it.list[icon].name+" angeboticon rechts"} title={it.list[icon].text} key={ic}></i>);
        }
      }
    }
    
    var cnt = data.rooms[this.state.cnt]?this.state.cnt:0;
    var img = konfig.bilder.preiskat[ data.rooms[cnt]?data.rooms[cnt].pkat:0 ];
    if (!img) {
      img = symbolicZimmerBild;
    }

    return (
      <div className="clearfix angebot_wrap">
        <div className="clearfix angebot">
          <div className="room vis" style={{backgroundImage:"url("+img+")"}} onClick={selectAngebot(data.angId)}>
      {data.mrtype === 'multiroom' || data.roomchange?
            <div className="clearfix multiroom"></div>
      :null}          
            <div className={"clearfix choice"+(selectedAngebotId===data.angId?" choice_selected":"")}>
              <div className="image gutzeichen"></div><p className="my_choice">{st['bwh.zusatz.ausgewaehlt']}</p>
            </div>
      {data.mrtype === 'multiroom'?
            <p className="multiroom_text">multiroom</p>
      :null}          
      {data.roomchange?
            <p className="multiroom_text" style={{fontSize: '1.1em'}}>roomchange</p>
      :null}          
            <div className="clearfix roomverlauf"></div>
            <div className="clearfix roompreis_wrap">
      {hasRates &&
              <p className="roomfrom">{st['bwh.preis.ab']}</p> 
      }
      {data.rabatt <0 && <p className="preisohnecoupon">{poc.toFixed(decimal)}</p>}
              <p className="roompreis">{ptot.toFixed(decimal)}</p>
              <p className="roomwaehrung">{waehrung}</p>
      {konfig.showaverageprice?<p className="roomperperson" dangerouslySetInnerHTML={{__html: st['bwh.preis.perperson']}} />:null}
      {Object.keys(data.preisAk).length>1 &&
              <p className="kinderpreis">{preisText}</p>  
              
              
      }
            </div>
          </div>
          <div className="clearfix angebotbeschreibung">
      {geschlecht!==0?
            <MaleIcon />
      :null}
      {geschlecht!==1?
            <FemaleIcon />
      :null}
            <p className="angebotbeschreibung-zeile1 angebotbeschreibung-zeile1-text">
      {data.rooms.map((el, k) => (
              <span key={k} style={{display:'block'}}>
                {pt.preiskat_bezeichnung[el.pkat] + getGenderText(el.geschlecht, konfig.hideprivatmarker)} 
              </span>
      ))}
            </p>
      {data.rooms.map((el, k) => (
            <React.Fragment key={k}>
            <p className="angebotbeschreibung-zeile2">{pt.preiskat_kurztext[el.pkat]}</p>
            <div className="clearfix angeboticons">
              <React.Fragment>
              {iconsLinks[k]}
              </React.Fragment>
              {this.state["detail"+k]?<PfeilObenGrauIcon onClick={this.clickDetail(k)}/>:<PfeilUntenGrauIcon onClick={this.clickDetail(k)} />}
              <React.Fragment>
              {iconsRechts}
              </React.Fragment>
            </div>
      {this.state["detail"+k]?      
            <div className="clearfix ausklappabschnitt">
              <p className="ausklapptext" dangerouslySetInnerHTML={{__html: textileConvert(pt.preiskat_langtext[el.pkat])}} />
      {konfig.icons[el.pkat].map((el2, j) => {
        if (it.list[el2]) {
          return (
                <div className="clearfix ausklappicon_wrap" key={j}>
                  <i className={"flaticon-"+it.list[el2].name+" ausklappicon"}></i>
                  <p className="ausklappicontext">{it.list[el2].text}</p>
                  {j===konfig.icons[el.pkat].length-1 && <PfeilObenGrauIcon onClick={this.clickDetail(k)} />}
                </div>
          )
        }
        else {
          return null;
        }
      } 
      )}
            </div>
      :null}
            </React.Fragment>
      ))}
          </div>
        </div>
      </div>            
    );
  }
}
export default Angebot;