import React from 'react';
import { st, pt } from './texte'
import Confirm from './Confirm'
import { constants, calcPreisJetzt } from './common';
import moment from 'moment';
import { InputFeld } from './Felder'
import { injectStripe } from 'react-stripe-elements';
import { ReactComponent as PfeilRechtsFettIcon } from './img/pfeil_rechts_fett.svg'
import { ReactComponent as GutzeichenIcon } from './img/gutzeichen.svg'
import { ReactComponent as KreuzIcon } from './img/kreuz-01.svg'
import ccamex from './img/wpcc_amex.gif'
import ccdiners from './img/wpcc_diners.gif'
import ccmastercard from './img/wpcc_mastercard.gif'
import ccvisa from './img/wpcc_visa.gif'

const isFieldRequired = (fieldConf, paymentinfo) => {
    var bRet = false;
    if (fieldConf === constants.PersDataObligatorisch) {
      bRet = true;
    }
    else if (fieldConf === constants.PersDataObligOhneKK 
       && paymentinfo.BookingFee === constants.bfNoPaymentInfo)
    {
      bRet = true;
    }
    return bRet;
  }

  
export class CCDatenNoStripe extends React.Component {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
    
    this.state = {};
    var gfConfig = props.konfig.guestFieldConfigData;
    for (var elId in gfConfig) {
      var conf = parseInt(gfConfig[elId]);
      this.state['req_'+elId] = isFieldRequired(conf, this.props.paymentinfo);
      this.state['dispv_'+elId] = 0;
      this.state['valid_'+elId] = !isFieldRequired(conf, this.props.paymentinfo);
    }
    if (parseInt(props.konfig.usearrivaltime)>1) {
      this.state['req_zArrivalTime'] = true;
      this.state['dispv_zArrivalTime'] = 0;
      this.state['valid_zArrivalTime'] = false;
    }
  }  
  
  fieldChange = (elId) => event => {
    var v = event.target.value;
    var valid = v.length>0 && v!=="0";
    this.setState({['dispv_'+elId]: valid?1:-1, ['valid_'+elId]:valid })
    this.props.change(elId, v);
  }
  
  checkAllFields = () => {
    var ok = true;
    for(var k in this.state) {
      var set = {};
      if (k.substr(0,2)==='cc_' && this.state[k]) {
        var elId = k.substr(2);
        var v = this.props.appState['cc_'+elId];
        if (!v) {
          v = '';
        }
        var invalid = false;
        invalid = v.length===0 || v==="0";
        if (!invalid) {
        }
        if (invalid) {
          set['dispv_'+elId] = -1;
          set['valid_'+elId] = false;
          ok = false;
        }
      }
      this.setState(set);
    }
    return ok;
  }
  
  async submit(ev) {
    const intent = await this.props.setBooking();
    if (intent.action_intent) {
      this.props.stripe.handleCardAction(
        intent.action_intent
      ).then(async (result) => {
        if (result.error) {
          var fehlertext = st['bwh.errormessage.booking.10'];
          this.props.setAppState({error: fehlertext.replace('{FEHLER}', result.error.message)});
        } else {
          await this.props.setBooking(result.paymentIntent.id);
        }
      });
    }
  }
  
  render (){
    const { konfig, appState, change, preis, ...other } = this.props;
    
    var sp = appState.sprache;
    if (sp!=='de' && sp!=='fr') {
      sp = 'en';
    }

    const jahrListe = [(<option value="0" key="0">-</option>)];
    var y = parseInt(moment().format('Y'));
    for(var i=0; i<=13; i++) {
      jahrListe.push(<option value={y+i} key={y+i}>{y + i}</option>);
    }
    
    const PreisJetzt = calcPreisJetzt(appState);
    const CCTypes = this.props.konfig.cctypes;

    return (
    <React.Fragment>
      <div id="zahlungsdatenFormular_wrap">
        <div className="clearfix viewheader">
          <p id="zahlungsdatenFormular_headertext" className="viewheadertext">{st['bwh.zahlungsdaten.formular.headertext']}</p>
        </div>
        <div id="zahlungsdatenFormular_formular" className="clearfix formularansicht">
          <div className="formularlegende clearfix">
            <p id="zahlungsdatenFormularLegende" className="formularlegende-text">{st['bwh.zahlungsdaten.legende']}</p>
          </div>
        {CCTypes && CCTypes.length>0 &&
          <div className="ccimgs">
            {CCTypes.indexOf('V')>-1 &&  <img src={ccvisa} alt="cc visa"/>}
            {CCTypes.indexOf('M')>-1 &&  <img src={ccmastercard} alt="cc mastercard"/>}
            {CCTypes.indexOf('A')>-1 &&  <img src={ccamex} alt="cc amex"/>}
            {CCTypes.indexOf('D')>-1 &&  <img src={ccdiners} alt="cc diner"/>}
          </div>        
        }
          <div id="zahlungsdatenFormular_formular_ohnestripe" className="clearfix formularansicht">
            <InputFeld name="inhaber" titel={st['bwh.zahlungsdaten.formular.inhaber'].slice(0,-1)} value={appState.cc_inhaber} required={true}
              valid={this.state.dispv_inhaber} change={this.fieldChange} {...other} />
            <InputFeld name="number" titel={st['bwh.zahlungsdaten.formular.nummer'].slice(0,-1)} value={appState.cc_number} required={true}
              valid={this.state.dispv_number} change={this.fieldChange} {...other} />
            <div className="formularfeld clearfix">
              <p id="zahlungsdatenFormularAblaufLabel" className="formularfeld-label">{st['bwh.zahlungsdaten.formular.ablauf']}</p>
              <div className="formularfeld-feld no-padding ohnegutzeichen clearfix">
                <select className="formularfeld-feld formularfeld-halbfeld mitvalid required"
                  onChange={this.fieldChange('expmonth')}  value={appState.cc_expmonth} id="zahlungsdatenFormularExpMonth">
                  <option label="-" value="0">-</option>
                  <option label="01" value="01">01</option>
                  <option label="02" value="02">02</option>
                  <option label="03" value="03">03</option>
                  <option label="04" value="04">04</option>
                  <option label="05" value="05">05</option>
                  <option label="06" value="06">06</option>
                  <option label="07" value="07">07</option>
                  <option label="08" value="08">08</option>
                  <option label="09" value="09">09</option>
                  <option label="10" value="10">10</option>
                  <option label="11" value="11">11</option>
                  <option label="12" value="12">12</option>
                </select>
              {this.state.dispv_expmonth===1 &&
                <GutzeichenIcon className="formularfeld-gutzeichen image halbfeld links" />
              }
              {this.state.dispv_expmonth===-1 &&
                <KreuzIcon className="formularfeld-kreuzzeichen image halbfeld links" />
              }
                {this.state.dispv_expyear===1?<GutzeichenIcon className="formularfeld-kreuzzeichen image halbfeld" />:
                  this.state.dispv_expyear===-1?<KreuzIcon className="formularfeld-kreuzzeichen image halbfeld" />:null} 
                <select className="formularfeld-feld formularfeld-halbfeld mitvalid formularfeld-halbfeld-rechts required"
                  onChange={this.fieldChange('expyear')}  value={appState.cc_expyear} id="zahlungsdatenFormularExpYear" >
                  {jahrListe}
                </select>
              </div>
            </div>
            <div id="zahlungsdatenFormularCVCFeld" className="formularfeld clearfix">
              <p id="zahlungsdatenFormularCVCLabel" className="formularfeld-label">{st['bwh.zahlungsdaten.formular.cvc']}</p>
              <div className="formularfeld-feld no-padding ohnegutzeichen clearfix">
                <input type="text" size="4" value={appState.cc_cvc}
                  className="formularfeld-feld formularfeld-feldcvc" onChange={this.fieldChange('cvc')}
                  id="zahlungsdatenFormularCvc" />
                {this.state.dispv_cvc===1 &&
                  <GutzeichenIcon className="formularfeld-gutzeichen image"  />
                }
                {this.state.dispv_cvc===-1 &&
                  <KreuzIcon className="formularfeld-kreuzzeichen image" />
                }
                <a href={"https://secure.dormproject.ch/online/templates/1_cvv_"+appState.sprache+".html"} id="zahlungsdatenFormularWocvcText" className="small" target="_blank" rel="noopener noreferrer" >
                  {st['bwh.zahlungsdaten.formular.wocvc']}
                </a>
              </div>
            </div>
            <div id="formularfeld clearfix" className="formularfeld clearfix">
            {PreisJetzt > 0?
              <h3>{st['bwh.zahlungpreis'].replace('%{preis}', PreisJetzt.toFixed(appState.paymentinfo.decimal) + ' ' + appState.paymentinfo.waehrung)}</h3>
              :
              <h3>{st['bwh.zahlung0']}</h3>
            }
            </div>
          </div>
        </div>
      </div>
      <Confirm appState={appState} {...other}/>
      <div id="boo_now_leiste_unten" className="book-now-leiste-wrap clearfix">
        <div className="book-now-leiste clearfix handcursor" onClick={this.submit}>
          <div className="book-now-wrap clearfix book-now-wrap-hover">
            <p id="book_now_preis">{st['bwh.preis.total']} {preis}</p>
            <div className="book-now clearfix">
              <p id="book_now_text_unten" className="book-now-text">{pt.hostel_booknowfinish}</p>
              <PfeilRechtsFettIcon />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
    );
  }
}
export default injectStripe(CCDatenNoStripe);
