import React from 'react';
import { st } from './texte'
const PaymentTable = ({appState, ratedetails, moment, dateFormat}) => {
 
  const SubTable = ({typ}) => {
    const list = ratedetails[typ];
    const tx_title = typ?'bwh.rate.titelstrono':'bwh.rate.titelplan';
    
    return (        
      <>
        <tr><td colSpan="3"><b>{st[tx_title]}</b></td></tr>
  {Object.keys(list).map((k)=>{
    const row = list[k];
    var date = '';
    if (row.dateTxt && row.dateTxt.substr(0,3)==='bwh') {
      date = st[row.dateTxt];
    }
    else {
      const d = moment(row.dateTxt);
      const prefix = typ===0?'bwh.rate.before':'bwh.rate.from';
      date = st[prefix] + ' ' + d.format(dateFormat);
    }
    if (!row.hasOwnProperty('preisMod')) return null;
    return (
      <tr key={k} >
        <td>{date}</td>
        <td>&nbsp;</td>
        <td align="right">{row.preisMod.toFixed(appState.paymentinfo.decimal)+' '+appState.paymentinfo.waehrung}</td>
      </tr>
    )    
  }
  )}
      
      </>
    )
  }
 
  return (
    <table className="paymentplan">
      <tbody>  
        <SubTable typ={0}  />
        <SubTable typ={1}  />
      </tbody>  
    </table>
  ) 
}

export default PaymentTable;